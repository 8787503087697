import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import "scroll-behavior-polyfill";
import { useSessionStorage } from "react-use";
import isbot from "isbot";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { DefaultSEO } from "./default-seo";

// Components
import { Splash } from "./splash";

// // Transition
import Transition from "../transition/transition";

const Container = styled.div`
  position: relative;
`;

const Main = styled.main`
  flex: 1;
  background-color: #fff;
`;

const Layout = ({ children }) => {
  // Splash
  const [showSplash, setShowSplash] = useState(true);
  // const [removeSplash, setRemoveSplash] = useState(false);
  const [removeSplash, setRemoveSplash] = useSessionStorage(
    "removeSplash",
    false
  );

  useEffect(() => {
    const slideUpSplashTimer = setTimeout(() => {
      setShowSplash(false);
      const removeSplashTimer = setTimeout(() => {
        setRemoveSplash(true);
      }, 500);
      return () => clearTimeout(removeSplashTimer);
    }, 2500);
    return () => clearTimeout(slideUpSplashTimer);
  }, []);

  useEffect(() => {
    if (showSplash === false) {
      const removeSplashTimer = setTimeout(() => {
        setRemoveSplash(true);
      }, 500);
      return () => clearTimeout(removeSplashTimer);
    }
  }, [showSplash]);

  const [isUserBot, setIsUserBot] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsUserBot(isbot(navigator.userAgent));
    }
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <>
      <Normalize />
      <GlobalStyles />
      <DefaultSEO />
      <Location>
        {({ location }) => (
          <Transition location={location}>
            <Container location={location.pathname}>
              <Main>{children}</Main>

              {!isUserBot && (
                <>
                  {!removeSplash && (
                    <Splash
                      showSplash={showSplash}
                      setShowSplash={setShowSplash}
                    />
                  )}
                </>
              )}
            </Container>
          </Transition>
        )}
      </Location>
    </>
  );
};

export default Layout;
