import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

html {
  scroll-behavior: smooth;
}

*:focus{
  outline:none;
}

body {
  font-family: "practice-book", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.1px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  // @media(max-width: 500px){
  //   font-size: 13px;
  //   line-height: 16px;
  // }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "practice-book", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;

  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.1px;

  // @media(max-width: 500px){
  //   font-size: 13px;
  //   line-height: 16px;
  // }
}

em{
  font-family: "practice-book-italic", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-style:normal;
}

strong {
  font-style:normal;
  font-weight:normal;

  font-feature-settings: 'sups';
  line-height: 1;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border:0;
    padding:0;
    margin:0;
    apperance:none;

    line-height: 24px;

    cursor:pointer;
    color: #000;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

// ::-webkit-input-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// ::-moz-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-ms-input-placeholder{
//       color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-moz-placeholder {
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

//   }

/*--------------------------------------------------------------
## General modals
--------------------------------------------------------------*/

body.ReactModal__Body--open{
  overflow: hidden;

  & #___gatsby{
    filter: blur(5px);
  }
}

.ReactModalPortal,
.ReactModal__Overlay--after-open {
  z-index: 1000;
  border: 0;
  overflow-y: scroll;
  height: 100%;

  background-color: transparent;
}

.about-lightbox-modal{
  height: 100%;
}

`;

export default GlobalStyle;
