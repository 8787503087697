import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10000;
  background-color: #fff;

  opacity: ${props => props.opacity};
  transition: 350ms opacity ease;

  & .content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    padding: 30px;

    height: 100%;

    & img {
      max-height: calc(100vh - 78px);
    }

    @media (max-width: 768px) {
      padding: 20px;

      & img {
        max-height: calc(100vh - 58px);
      }
    }

    & .content {
      max-width: 700px;

      & p {
        font-size: 14px;
        line-height: 17px;

        margin: 5px 0 0 0;
      }
    }
  }
`;

export const Splash = ({ showSplash, setShowSplash }) => {
  const data = useStaticQuery(graphql`
    {
      prismicSplash {
        data {
          image {
            fluid {
              srcWebp
              srcSetWebp
            }
            dimensions {
              height
              width
            }
            alt
          }
          caption {
            html
          }
        }
      }
    }
  `);

  return (
    <SplashContainer
      opacity={showSplash ? 1 : 0}
      onClick={() => {
        setShowSplash(false);
      }}
    >
      <div className="content-container">
        <div className="content">
          {data.prismicSplash.data.image.fluid !== null && (
            <img
              srcSet={data.prismicSplash.data.image.fluid.srcSetWebp}
              src={data.prismicSplash.data.image.fluid.srcWebp}
              alt={data.prismicSplash.data.image.alt}
              loading="lazy"
            />
          )}

          <div
            className="image-caption"
            dangerouslySetInnerHTML={{
              __html: data.prismicSplash.data.caption.html,
            }}
          />
        </div>
      </div>
    </SplashContainer>
  );
};
